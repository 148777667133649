<template>
  <b-form-group>
    <div class="d-flex justify-content-between">
      <label :for="name">{{ label }}</label>
      <slot></slot>
    </div>
    <validation-provider #default="{ errors }" :name="name" :rules="rules">
      <b-form-textarea
        :id="name"
        :value="value"
        :state="errors.length > 0 ? false : null"
        :name="name"
        :placeholder="placeholder"
        :rows="rows"
        :max-rows="maxRows"
        :maxlength="maxLength"
        @input="inputHandler"
        autocomplete="off"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";


export default {
  name: "BlInput",
  components: {
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
  },
  data: () => {
    return {};
  },
  methods: {
    inputHandler(value) {
      this.$emit("input", value);
    },
  },
  props: ["name", "rules", "placeholder", "value", "label", "rows", "maxRows", "maxLength"],
};
</script>

<style scoped>
</style>
