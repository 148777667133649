<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isActive"
    :category="category"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }" v-if="category">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Изменить категорию
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          v-slot="{invalid}"
      >
        <!-- Форма -->
        <b-form
          class="p-2"
          @submit.prevent
        >

          <b-row>
            <b-col cols="12" class="pr-2">
              <BlInput name="editName"
                       rules="required"
                       placeholder="Хиты продаж"
                       v-model="category.title"
                       label="Наименование категории"></BlInput>
            </b-col>

            <b-col cols="12">
              <BlInlinePictureForm @outputFile="outputFile"></BlInlinePictureForm>
            </b-col>
          </b-row>

          <b-button @click="onUpdate" variant="primary" class="mt-1" :disabled="invalid"> Изменить </b-button>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BMediaAside, BMedia, BMediaBody, BCol, BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BlInput from "@/components/controls/BlInput";
import BlInlinePictureForm from "@/components/controls/BlInlinePictureForm";
import '@/libs/custom-validate'
import api from "@/api";
import utils from "@/utils";

export default {
  components: {
    BlInlinePictureForm,
    BMediaBody,
    BMediaAside,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BlInput,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  methods: {
    outputFile(data){
      this.file = data.file
    },
    onUpdate(){
      const self = this;
      if (self.file) {
        api.files
            .upload(this.file)
            .then((response) => {
              const data = response.data.result;
              self.pathImage = data.path
              self.editCategory()
            })
            .catch((err) => {
              const data = err.response.data;
              if (data && data.message) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
      }
    },
    editCategory(){
        const self = this;
        api.posts
            .editCategory(
                this.category.id,
                this.category.title,
                this.pathImage
            )
            .then((response) => {
              const data = response.data;
              if (data && !data.error) {
                utils.notificate(self, data.message);
              }
            })
            .catch((err) => {
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
        this.$emit('editCategory', {
          category: this.category.id,
          title: this.category.title,
          picture: this.pathImage
        })
    },
  },

  props: ['isActive','category'],
  data() {
    return {
        name: '',
        featuredImage: null,
        file: null,
        pathImage: '',

    }
  },
  computed: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
