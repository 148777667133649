<template>
  <div class="blog-categories mt-3">

    <ModalNewCategory @addCategory="addCategory"
                      :is-active="isAddNewCategorySidebarActive">
    </ModalNewCategory>
    <ModalEditCategory @editCategory="editCategory"
                       :category="category"
                       :is-active="isEditCategorySidebarActive">
    </ModalEditCategory>
    <h6 class="section-label mb-1">
      Категории постов
    </h6>

    <!-- Добавить категорию -->
    <div v-if="$store.state.app.user && $store.state.app.user.account_type === 'ADMIN'"
        class="d-flex justify-content-start align-items-center mb-75"
        @click="isAddNewCategorySidebarActive =! isAddNewCategorySidebarActive"
    >
      <b-link>
        <b-avatar
            rounded
            size="32"
            variant="light-primary"
            class="mr-75"
        >
          <feather-icon
              icon="PlusIcon"
              size="16"
          />
        </b-avatar>
      </b-link>
      <b-link>
        <div class="blog-category-title text-body">
          Добавить категорию
        </div>
      </b-link>
    </div>

    <!-- Категории -->
    <div
        v-for="category in categories"
        :key="category.id"
        class="d-flex justify-content-between align-items-center mb-75"
    >
      <div class="align-items-center justify-content-start d-flex">
        <b-link>
          <b-avatar
              rounded
              size="32"
              variant="light-primary"
              class="mr-75"
              :src="category.picture"
              @click="sortByCategory(category)"
          >
          </b-avatar>
        </b-link>
        <b-link @click="sortByCategory(category)">
          <div class="blog-category-title text-body">
            {{ category.title }}
          </div>
        </b-link>
      </div>
      <div v-if="$store.state.app.user && $store.state.app.user.account_type === 'ADMIN'">
        <b-link >
          <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
              @click="editCategory(category)"
          />
        </b-link>
        <b-link class="pl-1">
          <feather-icon
              icon="TrashIcon"
              size="16"
              class="align-middle text-body"
              @click="deleteCategory(category)"
          />
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import ModalNewCategory from "@/components/views/news/modals/ModalNewCategory";
import api from "@/api";
import utils from "@/utils";
import ModalEditCategory from "@/components/views/news/modals/ModalEditCategory";

export default {
  name: "CategoriesList",
  components: {
    ModalEditCategory,
    BLink, BAvatar, ModalNewCategory
  },
  methods: {
    addCategory(data) {
      this.isAddNewCategorySidebarActive = !this.isAddNewCategorySidebarActive
      this.categories.push(data)
      this.$emit('loadCategories')
    },
    editCategory(category) {
      this.isEditCategorySidebarActive = !this.isEditCategorySidebarActive
      this.category = category
      this.$emit('loadCategories')
    },
    deleteCategory(category) {
      const self = this;
      api.posts
          .deleteCategory(
              category.id
          )
          .then((response) => {
            const data = response.data;
            if (data && !data.error) {
              utils.notificate(self, data.message);
              this.categories.splice(this.categories.indexOf(category), 1)
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      this.$emit('loadCategories')
    },

    sortByCategory(category){
      this.$emit('sortByCategory', {
        id: category.id,
        title: category.title,
        category: category
      })
    }
  },

  props: ['categories'],
  data() {
    return {
      isAddNewCategorySidebarActive: false,
      isEditCategorySidebarActive: false,
      category: null
    }
  },
}
</script>

<style scoped>

</style>
