<template>
  <div>
    <b-row class="pl-1">
      <validation-provider #default="{ errors }" name="imageProvider" rules="required">
      <BFormInput placeholder="Выберите изображение"
                  v-model="localImage"
                  disabled
                  class="col-12 rounded-0">
      </BFormInput>
      <b-form-file
          ref="refInputEl"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @submit.prevent
          @change="onFileChange"
      />
      </validation-provider>
      <b-button variant="primary"
                @click="$refs.refInputEl.$el.click()"
                class="rounded-0 col-sm-4 col-12 mt-2 mt-sm-0">
        Открыть
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
    BRow,
    BCol,
    BFormInput,
    BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
name: "BlInlinePictureForm",
  components: {
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormFile,
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
      this.localImage = files[0].name
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
      this.$emit('outputFile', {file: file})
    },
  },
  props: ['file'],
  computed: {
  },
  data() {
    return{
      localImage: null,
    }
  }
}
</script>

<style scoped>
</style>
